<template>
  <div>
    <PostFormComp pageTitle="Home Guru" showPreviewLink='1' thumenailDescription="ขนาดรูปแนะนำ 980x630 pixel" />
  </div>
</template>

<script>
import PostFormComp from '@/components/content/post/PostFormComp.vue';

export default {
  components: {
    PostFormComp
  },
  created() {
    this.$store.commit('setPageTitle', 'Home Guru');
    this.$store.commit('setHeadTitle', 'Home Guru');
  },
}
</script>